import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/global.scss"
import EnglishProgram from "../assets/Dan_Leslie program_English.pdf"
import RussianProgram from "../assets/Dan_Leslie_program_Russian.pdf"

const IndexPage = () => (
  <Layout title="Dan Leslie Memorial Meeting">
    <SEO title="Dan Leslie Memorial Meeting" />
    <section>
      <div className="container">
        <div className="columns is-8 is-variable">
          <div className="column">
            <iframe
              width="100%"
              height="600"
              src="https://www.youtube.com/embed/zKVOzyAw26w?si=xClkuVzkMBh_Iuwv"
              title="Dan Leslie Memorial Meeting"
              frameborder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </div>
      </div>
      <p style={{ marginTop: "-20px" }}><i>Note: Certain video testimonies do not have translation. We apologize for the inconvenience.</i></p>
      <div className="columns is-8 is-variable testimonies">
        <div className="column testimonies" style={{marginTop: "2em"}}>
          <p>Dan was born on September 20, 1946, in Seattle, Washington. He was the second of five children, and the first son of
Genevieve and George Leslie.</p>
<p>A month before Dan’s ninth birthday he accepted Christ as his
Lord and Savior. This was a significant, life-changing event that
shaped and directed his path as a young adult. His love for the
Lord and his desire to follow Him eventually brought him to the
church in Seattle in 1970. It was there that he fully consecrated
his whole life to the Lord.</p>
<p>Dan graduated from Auburn High School in Washington in
1965. He actively participated in school clubs and student body
government and was honored to be selected to Boys State. Following
high school, he attended Seattle Pacific College. Dan enjoyed
skiing and worked as a ski instructor as a young adult. He worked at
a few different jobs while growing up, but his diverse career path
eventually settled into procurement, where he handled purchasing
for government contracts in the aerospace industry. The opportunity
to work and serve by purchasing supplies and materials for the
construction brothers at the property on Ball Road in Anaheim in the
1980s was instrumental in this career choice.</p>
<p>In 1970, Dan met his wife Dorothy in the church in Seattle. They
were married on September 5, 1971, in Seattle, Washington. They
began their married life and church life together in Portland,
Oregon. In 1974, they moved to Anaheim, California, and two years
later, in 1976, they were burdened to begin the church life in
Cypress, California. Both Dan and Dorothy had the same mind, goals
and desires for their family, and they were blessed with three children,
Leanna, Stephen and Deborah.</p>
<p>Dan was fun-loving and playful; he loved to tease his family,
especially his three children and six grandchildren, playing games
with them and trying to teach them how to play his trumpet. He
made reading so fun for his three young children by changing
the story plot and character voices according to his “dad humor,”
which made the children laugh; they loved having daddy read. He
also loved to talk with them about the Lord as they grew, asking them
how they were doing, and answering their questions if he was
able. His earnest desire was for his children to grow up loving the
Lord.</p>
<p>Dan and Dorothy enjoyed serving in the church in Cypress for
many years when the Lord began to move in Dan’s spirit to drop
his job to serve the Lord full time. In 1986, he obeyed that calling and
the Lord led him to Taipei, Taiwan, that August. He was thinking that he
would be involved in the full-time training that was about to begin but
he received a different aspect of that training, along with all the
brothers! To their surprise the first item on their schedule involved
the digging of a tunnel to create access between two halls.
Covered with dirt from head to toe every day for several months, the
brothers labored on that tunnel. One group started from one
building, while another group started from the other in order to
meet in the middle. However, the two tunnels did not meet up as
planned! More labor was needed to complete the job. This was a
real training and one of Dan’s fondest memories from that time. He
always got a good laugh out of it. The following February, 1987, Dan
came to get his family to join him in Taipei. The family also enjoyed and
benefited from the unique experience of living, serving and going to
school with families from many countries.</p>
<p>Upon returning to the US later that year, Dan resumed work until
1990, when he again began to serve the Lord full time as an
instructor at the training center in Anaheim. He particularly
enjoyed teaching an extra-curricular singing class. This class
prepared the trainees to go to many campuses, singing and giving
their testimonies for the gospel.</p>
<p>Dan loved music his whole life, and God gave him a real gift.
As a young man, he sang in a youth choir, and he even began
directing young people’s singing groups. After coming into the
church he could often be found with others around a piano
singing his favorite hymns or playing his trumpet. One thing that
always made the family so happy were the times when saints would
come over for lunch after a meeting. After having sweet fellowship
around the table, they would gather in the living room to sing,
enjoying the Lord together as Dan played the trumpet and his
family accompanied him on the piano and guitar. Music filled their
home with joy.</p>
<p>Over the years, Dan and Dorothy participated in the recordings
of the semiannual training banner songs, enjoyed by many
saints. They were honored to open their home for the recording of
the Song of Songs that Brother Howard Higashi had put to music.
Having the saints come to sing filled their home with enjoyment and
the flow of life. Dan kept his love for the hymns, even after becoming
ill. The family shares special eternal memories of gathering around
his bed to sing hymns with him. With his weakened voice, he
prayerfully repeated after one of the hymns: “The love of God and the grace of Christ, and
fellowship of the Holy Spirit, be with you, be with you all, be with you
all, be with you all.” The family received this sweet blessing. Every
heart was touched, from Dan down to the youngest grandchild.
Dan also loved meeting new people. Being a good listener, with
a friendly, caring nature, he was well-loved and appreciated by
those who knew him. He also became a father to many, as so many
have testified, through his shepherding of them. Dan never cared to
be in the spotlight, but willingly did whatever was asked of him. He
learned valuable lessons along his church life journey, having no
idea of the Lord’s plan for him. In the early 1990s, around the same
time the Lord opened the door for saints to go to Russia, Dan served
with Brother Francis Ball in the Lord’s Move to Europe office.
Together, Francis and he coordinated to supply the practical needs
of the saints who migrated there. After serving in this capacity for
several years, the Lord, through the serving brothers, asked Dan
to serve in the training center in Moscow, Russia. Although Dan
had enjoyed laboring to supply the saints, he wasn’t sure that he
wanted to go there himself; however, he was willing to pray about
it. After much prayer and seeking the Lord, he had the assurance
that the Lord would care for all his concerns, and all his prayers
were answered, even above all that he asked. He and Dorothy
moved to Moscow in 1998 and served there for 15 years, enjoying
their labor in the Lord. There was something genuine and eternal
built up among the serving brothers who gathered together in
coordination from Taiwan, Korea, the US and Russia. Through their
coordination together the Lord was able to break through each
culture to gain the oneness among all the brothers. This deeply
touched Dan. He and Dorothy treasured their time there.</p>
<p>Upon returning to the US in 2013, Dan continued serving in the
church in Cypress, as well as in the middle-age training in
Anaheim until he began to grow ill in October of 2022. Even though
he grew progressively weaker, he never forgot his family’s or the
saints’ names, nor did he forget the lyrics to the hymns. His family
could still go to him with a tune and he would remind them of the
words. How wonderful it was to still have that part of him until the end!</p>
          <p>Dan and Dorothy shared 52 years together, loving the Lord and serving Him.</p>
          <p>On the morning of November 7, 2023, at the age of 77, Dan passed away peacefully in his sleep. He is greatly missed.</p>
        </div>
      </div>
      <div className="columns is-8 is-variable testimonies">
        <div className="column testimonies memorial" style={{marginTop: "2em"}}>
          <p><a href={EnglishProgram}>Memorial Program - English</a></p>
          <p><a href={RussianProgram}>Memorial Program - Russian</a></p>
        </div>
      </div>
    </div>
  </section>
</Layout>
)

export default IndexPage